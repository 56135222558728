<template>
  <div class="container">
    <div class="center">
        <router-link :to="{name:'Menu'}" @click.native="onClickButton">
            <button class="btn1">MENU</button>
        </router-link>
        <button class="btn2" onclick="window.location.href='https://e2102.square.site';">VISIT OUR ONLINESHOP</button>
        <button class="btn3" onclick="window.location.href='https://instagram.com/e2102.cafe';">VISIT OUR INSTAGRAM</button>
    </div>
    <div class="footer-logo">
        <img class="logo" v-bind:src="imgPath">
    </div>
  </div>
</template>

<script>

document.addEventListener("dblclick", function(e){ e.preventDefault();}, { passive: false });

export default {
  data() {
    return {
    imgPath: require('@/assets/logo.png')
    }
  }
}
</script>


<style scoped>

@font-face {
  font-family: '16bit-Regular';
  src: url('~@/assets/fonts/16bit-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'game_over';
  src: url('~@/assets/fonts/game_over.ttf') format('truetype');
}

@font-face {
  font-family: 'Game_play';
  src: url('~@/assets/fonts/Gameplay.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  text-decoration: none;
  font-family: Game_play, Helvetica;
}

.center {
  width: 20%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button {
  width: 100%;
  font-size: 0.8rem;
  border-radius: 7px;
  padding: 12px 24px;
  margin-bottom: 15px;
  border:#000000 solid 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease;
}

.btn1 {
  background-color: #000000;
}

.btn1 {
    color: #ffffff;
}

.btn2 .btn3 {
  background-color: #ffffff;
  color: #000000;
}

.button:hover {
  cursor: pointer;
}

.button:active {
  opacity: 0.5;
}

.footer-logo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.logo {
  width: 60px;
  height: 15px;
}

@media screen and (max-width: 959px) {
/* 959px以下に適用されるCSS（タブレット用） */
    .center {
        width: 60%;
    }
}

@media screen and (max-width: 480px) {
/* 480px以下に適用されるCSS（スマホ用） */
    .center {
        width: 80%;
    }
    button {
        font-size: 0.7rem;
    }
}

</style>